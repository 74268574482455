import "./loading.css";

const Loading = () => {
  return (
    <div className="loading-wrap">
      <span className="loader"></span>
    </div>
  );
};
export default Loading;
